/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Rank = "class_" | "cohort" | "division" | "domain" | "family" | "form" | "genus" | "hyperfamily" | "informal" | "infraclass" | "infrafamily" | "infrakingdom" | "infraorder" | "infraphylum" | "infrasubspecific" | "infratribe" | "kingdom" | "order" | "other" | "parvorder" | "phylum" | "root" | "species" | "species_group" | "subclass" | "subcohort" | "subfamily" | "subgenus" | "subkingdom" | "suborder" | "subphylum" | "subspecies" | "subtribe" | "superclass" | "supercohort" | "superfamily" | "superkingdom" | "superorder" | "superphylum" | "synonym" | "tribe" | "unranked" | "variety" | "%future added value";
export type TaxonContext_taxon = {
    readonly class_: {
        readonly rank: Rank;
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly order: {
        readonly rank: Rank;
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly family: {
        readonly rank: Rank;
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly " $refType": "TaxonContext_taxon";
};
export type TaxonContext_taxon$data = TaxonContext_taxon;
export type TaxonContext_taxon$key = {
    readonly " $data"?: TaxonContext_taxon$data;
    readonly " $fragmentRefs": FragmentRefs<"TaxonContext_taxon">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rank",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaxonContext_taxon",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Taxon",
      "kind": "LinkedField",
      "name": "class_",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Taxon",
      "kind": "LinkedField",
      "name": "order",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Taxon",
      "kind": "LinkedField",
      "name": "family",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Taxon",
  "abstractKey": null
};
})();
(node as any).hash = '5ccfd83f48c045e9f98177aeebe042d8';
export default node;
