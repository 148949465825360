/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Body_model = {
    readonly __typename: string;
    readonly " $fragmentRefs": FragmentRefs<"NameBody_name" | "PersonBody_person" | "OccurrenceBody_occurrence" | "NameCommentBody_nameComment" | "SpeciesNameEndingBody_speciesNameEnding" | "NameEndingBody_nameEnding" | "NameComplexBody_nameComplex" | "SpeciesNameComplexBody_speciesNameComplex" | "TaxonBody_taxon" | "LocationBody_location" | "CollectionBody_collection" | "ArticleCommentBody_articleComment" | "ArticleBody_article" | "CitationGroupPatternBody_citationGroupPattern" | "CitationGroupBody_citationGroup" | "RegionBody_region" | "PeriodBody_period" | "StratigraphicUnitBody_stratigraphicUnit">;
    readonly " $refType": "Body_model";
};
export type Body_model$data = Body_model;
export type Body_model$key = {
    readonly " $data"?: Body_model$data;
    readonly " $fragmentRefs": FragmentRefs<"Body_model">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Body_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameBody_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonBody_person"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceBody_occurrence"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameCommentBody_nameComment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpeciesNameEndingBody_speciesNameEnding"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameEndingBody_nameEnding"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameComplexBody_nameComplex"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpeciesNameComplexBody_speciesNameComplex"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxonBody_taxon"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LocationBody_location"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionBody_collection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleCommentBody_articleComment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleBody_article"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CitationGroupPatternBody_citationGroupPattern"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CitationGroupBody_citationGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegionBody_region"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PeriodBody_period"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StratigraphicUnitBody_stratigraphicUnit"
    }
  ],
  "type": "Model",
  "abstractKey": "__isModel"
};
(node as any).hash = '79c92f012584a1cb399f637a19d0514d';
export default node;
