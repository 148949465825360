/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SearchSectionQueryVariables = {};
export type SearchSectionQueryResponse = {
    readonly taxonCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
    readonly nameCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
    readonly collectionCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
    readonly regionCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
    readonly locationCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
    readonly periodCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
    readonly stratigraphicUnitCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
    readonly citationGroupCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
    readonly nameComplexCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
    readonly speciesNameComplexCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
    readonly personCls: {
        readonly " $fragmentRefs": FragmentRefs<"SearchBox_modelCls">;
    };
};
export type SearchSectionQuery = {
    readonly response: SearchSectionQueryResponse;
    readonly variables: SearchSectionQueryVariables;
};



/*
query SearchSectionQuery {
  taxonCls: modelCls(callSign: "T") {
    ...SearchBox_modelCls
  }
  nameCls: modelCls(callSign: "N") {
    ...SearchBox_modelCls
  }
  collectionCls: modelCls(callSign: "C") {
    ...SearchBox_modelCls
  }
  regionCls: modelCls(callSign: "R") {
    ...SearchBox_modelCls
  }
  locationCls: modelCls(callSign: "L") {
    ...SearchBox_modelCls
  }
  periodCls: modelCls(callSign: "P") {
    ...SearchBox_modelCls
  }
  stratigraphicUnitCls: modelCls(callSign: "S") {
    ...SearchBox_modelCls
  }
  citationGroupCls: modelCls(callSign: "CG") {
    ...SearchBox_modelCls
  }
  nameComplexCls: modelCls(callSign: "NC") {
    ...SearchBox_modelCls
  }
  speciesNameComplexCls: modelCls(callSign: "SC") {
    ...SearchBox_modelCls
  }
  personCls: modelCls(callSign: "H") {
    ...SearchBox_modelCls
  }
}

fragment SearchBox_modelCls on ModelCls {
  callSign
  name
  autocompletions
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "T"
  }
],
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "SearchBox_modelCls"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "N"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "C"
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "R"
  }
],
v5 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "L"
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "P"
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "S"
  }
],
v8 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "CG"
  }
],
v9 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "NC"
  }
],
v10 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "SC"
  }
],
v11 = [
  {
    "kind": "Literal",
    "name": "callSign",
    "value": "H"
  }
],
v12 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "callSign",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "autocompletions",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchSectionQuery",
    "selections": [
      {
        "alias": "taxonCls",
        "args": (v0/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"T\")"
      },
      {
        "alias": "nameCls",
        "args": (v2/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"N\")"
      },
      {
        "alias": "collectionCls",
        "args": (v3/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"C\")"
      },
      {
        "alias": "regionCls",
        "args": (v4/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"R\")"
      },
      {
        "alias": "locationCls",
        "args": (v5/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"L\")"
      },
      {
        "alias": "periodCls",
        "args": (v6/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"P\")"
      },
      {
        "alias": "stratigraphicUnitCls",
        "args": (v7/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"S\")"
      },
      {
        "alias": "citationGroupCls",
        "args": (v8/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"CG\")"
      },
      {
        "alias": "nameComplexCls",
        "args": (v9/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"NC\")"
      },
      {
        "alias": "speciesNameComplexCls",
        "args": (v10/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"SC\")"
      },
      {
        "alias": "personCls",
        "args": (v11/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": "modelCls(callSign:\"H\")"
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SearchSectionQuery",
    "selections": [
      {
        "alias": "taxonCls",
        "args": (v0/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"T\")"
      },
      {
        "alias": "nameCls",
        "args": (v2/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"N\")"
      },
      {
        "alias": "collectionCls",
        "args": (v3/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"C\")"
      },
      {
        "alias": "regionCls",
        "args": (v4/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"R\")"
      },
      {
        "alias": "locationCls",
        "args": (v5/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"L\")"
      },
      {
        "alias": "periodCls",
        "args": (v6/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"P\")"
      },
      {
        "alias": "stratigraphicUnitCls",
        "args": (v7/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"S\")"
      },
      {
        "alias": "citationGroupCls",
        "args": (v8/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"CG\")"
      },
      {
        "alias": "nameComplexCls",
        "args": (v9/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"NC\")"
      },
      {
        "alias": "speciesNameComplexCls",
        "args": (v10/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"SC\")"
      },
      {
        "alias": "personCls",
        "args": (v11/*: any*/),
        "concreteType": "ModelCls",
        "kind": "LinkedField",
        "name": "modelCls",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": "modelCls(callSign:\"H\")"
      }
    ]
  },
  "params": {
    "cacheID": "ad3631ea35dda09fd358f99a0d26d3f8",
    "id": null,
    "metadata": {},
    "name": "SearchSectionQuery",
    "operationKind": "query",
    "text": "query SearchSectionQuery {\n  taxonCls: modelCls(callSign: \"T\") {\n    ...SearchBox_modelCls\n  }\n  nameCls: modelCls(callSign: \"N\") {\n    ...SearchBox_modelCls\n  }\n  collectionCls: modelCls(callSign: \"C\") {\n    ...SearchBox_modelCls\n  }\n  regionCls: modelCls(callSign: \"R\") {\n    ...SearchBox_modelCls\n  }\n  locationCls: modelCls(callSign: \"L\") {\n    ...SearchBox_modelCls\n  }\n  periodCls: modelCls(callSign: \"P\") {\n    ...SearchBox_modelCls\n  }\n  stratigraphicUnitCls: modelCls(callSign: \"S\") {\n    ...SearchBox_modelCls\n  }\n  citationGroupCls: modelCls(callSign: \"CG\") {\n    ...SearchBox_modelCls\n  }\n  nameComplexCls: modelCls(callSign: \"NC\") {\n    ...SearchBox_modelCls\n  }\n  speciesNameComplexCls: modelCls(callSign: \"SC\") {\n    ...SearchBox_modelCls\n  }\n  personCls: modelCls(callSign: \"H\") {\n    ...SearchBox_modelCls\n  }\n}\n\nfragment SearchBox_modelCls on ModelCls {\n  callSign\n  name\n  autocompletions\n}\n"
  }
};
})();
(node as any).hash = 'fe05c87654809fd94a9dca14fb75803c';
export default node;
