/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PersonType = "alias" | "checked" | "deleted" | "hard_redirect" | "soft_redirect" | "unchecked" | "%future added value";
export type Status = "composite" | "dubious" | "hybrid" | "nomen_dubium" | "redirect" | "removed" | "species_inquirenda" | "spurious" | "synonym" | "valid" | "%future added value";
export type ModelLink_model = {
    readonly oid: number;
    readonly __typename: string;
    readonly locationRegion?: {
        readonly regionName: string;
    };
    readonly minPeriod?: {
        readonly periodName: string;
    } | null;
    readonly maxPeriod?: {
        readonly periodName: string;
    } | null;
    readonly stratigraphicUnit?: {
        readonly periodName: string;
    } | null;
    readonly city?: string | null;
    readonly status?: Status;
    readonly correctedOriginalName?: string | null;
    readonly taxon?: {
        readonly validName: string;
        readonly " $fragmentRefs": FragmentRefs<"Title_model">;
    };
    readonly birth?: string | null;
    readonly death?: string | null;
    readonly bio?: string | null;
    readonly personType?: PersonType;
    readonly source?: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLinkNoExtra_model">;
    } | null;
    readonly date?: number;
    readonly page?: string | null;
    readonly text?: string;
    readonly " $fragmentRefs": FragmentRefs<"ModelLinkNoExtra_model">;
    readonly " $refType": "ModelLink_model";
};
export type ModelLink_model$data = ModelLink_model;
export type ModelLink_model$key = {
    readonly " $data"?: ModelLink_model$data;
    readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ModelLinkNoExtra_model"
},
v1 = [
  {
    "alias": "periodName",
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModelLink_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "locationRegion",
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            {
              "alias": "regionName",
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Period",
          "kind": "LinkedField",
          "name": "minPeriod",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Period",
          "kind": "LinkedField",
          "name": "maxPeriod",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StratigraphicUnit",
          "kind": "LinkedField",
          "name": "stratigraphicUnit",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "type": "Location",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        }
      ],
      "type": "Collection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correctedOriginalName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Taxon",
          "kind": "LinkedField",
          "name": "taxon",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "validName",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Title_model"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Name",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "death",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bio",
          "storageKey": null
        },
        {
          "alias": "personType",
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "type": "Person",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Article",
          "kind": "LinkedField",
          "name": "source",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "page",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "type": "NameComment",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "type": "ArticleComment",
      "abstractKey": null
    }
  ],
  "type": "Model",
  "abstractKey": "__isModel"
};
})();
(node as any).hash = '692df326b907b2232fe5d12ee69b656d';
export default node;
