/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Group = "family" | "genus" | "high" | "species" | "%future added value";
export type NomenclatureStatus = "anonymous_authorship" | "art_13_nomen_oblitum" | "as_emended" | "available" | "based_on_a_suppressed_name" | "based_on_homonym" | "before_1758" | "collective_group" | "conditional" | "fully_suppressed" | "hybrid_as_such" | "hybrid_name" | "hypothetical_concept" | "inconsistently_binominal" | "incorrect_original_spelling" | "incorrect_subsequent_spelling" | "informal" | "infrasubspecific" | "ites_name" | "justified_emendation" | "mandatory_change" | "multiple_words" | "name_combination" | "no_type_specified" | "nomen_novum" | "nomen_nudum" | "not_based_on_a_generic_name" | "not_explicitly_new" | "not_intended_as_a_scientific_name" | "not_latin_alphabet" | "not_nominative_singular" | "not_published_with_a_generic_name" | "not_used_as_genus_plural" | "not_used_as_valid" | "partially_suppressed" | "preoccupied" | "rejected_by_fiat" | "reranking" | "subsequent_usage" | "teratological" | "type_not_treated_as_valid" | "unjustified_emendation" | "unlisted" | "unpublished" | "unpublished_electronic" | "unpublished_pending" | "unpublished_supplement" | "unpublished_thesis" | "variant" | "variety_or_form" | "work_of_extant" | "zoological_formula" | "%future added value";
export type Rank = "class_" | "cohort" | "division" | "domain" | "family" | "form" | "genus" | "hyperfamily" | "informal" | "infraclass" | "infrafamily" | "infrakingdom" | "infraorder" | "infraphylum" | "infrasubspecific" | "infratribe" | "kingdom" | "order" | "other" | "parvorder" | "phylum" | "root" | "species" | "species_group" | "subclass" | "subcohort" | "subfamily" | "subgenus" | "subkingdom" | "suborder" | "subphylum" | "subspecies" | "subtribe" | "superclass" | "supercohort" | "superfamily" | "superkingdom" | "superorder" | "superphylum" | "synonym" | "tribe" | "unranked" | "variety" | "%future added value";
export type SpeciesGroupType = "holotype" | "lectotype" | "neotype" | "nonexistent" | "syntypes" | "%future added value";
export type TypeSpeciesDesignation = "absolute_tautonymy" | "designated_by_the_commission" | "implicit" | "linnaean_tautonymy" | "misidentification" | "monotypy" | "original_designation" | "subsequent_designation" | "subsequent_monotypy" | "undesignated" | "%future added value";
export type NameBody_name = {
    readonly taxon: {
        readonly " $fragmentRefs": FragmentRefs<"TaxonContext_taxon">;
    };
    readonly originalName: string | null;
    readonly correctedOriginalName: string | null;
    readonly group: Group;
    readonly rootName: string;
    readonly authorTags: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"AuthorList_authorTags">;
    }>;
    readonly year: string | null;
    readonly numericYear: number | null;
    readonly pageDescribed: string | null;
    readonly originalCitation: {
        readonly " $fragmentRefs": FragmentRefs<"Reference_article">;
    } | null;
    readonly verbatimCitation: string | null;
    readonly citationGroup: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly nomenclatureStatus: NomenclatureStatus;
    readonly originalRank: Rank | null;
    readonly nameComplex: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly speciesNameComplex: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly typeSpecimen: string | null;
    readonly collection: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly nameType: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly genusTypeKind: TypeSpeciesDesignation | null;
    readonly speciesTypeKind: SpeciesGroupType | null;
    readonly typeLocality: {
        readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"NameTypeTags_name" | "NameTags_name" | "NameTypifiedNames_name" | "NamePreoccupiedNames_name" | "NameJuniorPrimaryHomonyms_name" | "NameJuniorSecondaryHomonyms_name" | "NameUnjustifiedEmendations_name" | "NameIncorrectSubsequentSpellings_name" | "NameNominaNova_name" | "NameVariants_name" | "NameTakingPriority_name" | "NameNominaOblita_name" | "NameMandatoryChanges_name" | "NameNameCombinations_name" | "NameIncorrectOriginalSpellings_name" | "NameSubsequentUsages_name" | "NameSelectionsOfPriority_name" | "NameSelectionsOfSpelling_name" | "NameReversalsOfPriority_name" | "NameJustifiedEmendations_name" | "NameDesignatedAsType_name" | "NameCommissionDesignatedAsType_name" | "NameComments_name">;
    readonly " $refType": "NameBody_name";
};
export type NameBody_name$data = NameBody_name;
export type NameBody_name$key = {
    readonly " $data"?: NameBody_name$data;
    readonly " $fragmentRefs": FragmentRefs<"NameBody_name">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameBody_name",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Taxon",
      "kind": "LinkedField",
      "name": "taxon",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaxonContext_taxon"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "correctedOriginalName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "group",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rootName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "authorTags",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AuthorList_authorTags"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "year",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numericYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageDescribed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "originalCitation",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Reference_article"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verbatimCitation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CitationGroup",
      "kind": "LinkedField",
      "name": "citationGroup",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nomenclatureStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalRank",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NameComplex",
      "kind": "LinkedField",
      "name": "nameComplex",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SpeciesNameComplex",
      "kind": "LinkedField",
      "name": "speciesNameComplex",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeSpecimen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Collection",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "nameType",
      "args": null,
      "concreteType": "Name",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "genusTypeKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "speciesTypeKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "typeLocality",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameTypeTags_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameTags_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameTypifiedNames_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NamePreoccupiedNames_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameJuniorPrimaryHomonyms_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameJuniorSecondaryHomonyms_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameUnjustifiedEmendations_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameIncorrectSubsequentSpellings_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameNominaNova_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameVariants_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameTakingPriority_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameNominaOblita_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameMandatoryChanges_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameNameCombinations_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameIncorrectOriginalSpellings_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameSubsequentUsages_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameSelectionsOfPriority_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameSelectionsOfSpelling_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameReversalsOfPriority_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameJustifiedEmendations_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameDesignatedAsType_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameCommissionDesignatedAsType_name"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NameComments_name"
    }
  ],
  "type": "Name",
  "abstractKey": null
};
})();
(node as any).hash = '619bf663e1e4438792f94cecf900e2d6';
export default node;
