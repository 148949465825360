/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Group = "family" | "genus" | "high" | "species" | "%future added value";
export type NomenclatureStatus = "anonymous_authorship" | "art_13_nomen_oblitum" | "as_emended" | "available" | "based_on_a_suppressed_name" | "based_on_homonym" | "before_1758" | "collective_group" | "conditional" | "fully_suppressed" | "hybrid_as_such" | "hybrid_name" | "hypothetical_concept" | "inconsistently_binominal" | "incorrect_original_spelling" | "incorrect_subsequent_spelling" | "informal" | "infrasubspecific" | "ites_name" | "justified_emendation" | "mandatory_change" | "multiple_words" | "name_combination" | "no_type_specified" | "nomen_novum" | "nomen_nudum" | "not_based_on_a_generic_name" | "not_explicitly_new" | "not_intended_as_a_scientific_name" | "not_latin_alphabet" | "not_nominative_singular" | "not_published_with_a_generic_name" | "not_used_as_genus_plural" | "not_used_as_valid" | "partially_suppressed" | "preoccupied" | "rejected_by_fiat" | "reranking" | "subsequent_usage" | "teratological" | "type_not_treated_as_valid" | "unjustified_emendation" | "unlisted" | "unpublished" | "unpublished_electronic" | "unpublished_pending" | "unpublished_supplement" | "unpublished_thesis" | "variant" | "variety_or_form" | "work_of_extant" | "zoological_formula" | "%future added value";
export type SpeciesGroupType = "holotype" | "lectotype" | "neotype" | "nonexistent" | "syntypes" | "%future added value";
export type Status = "composite" | "dubious" | "hybrid" | "nomen_dubium" | "redirect" | "removed" | "species_inquirenda" | "spurious" | "synonym" | "valid" | "%future added value";
export type NameList_connection = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly oid: number;
            readonly status: Status;
            readonly rootName: string;
            readonly typeTags?: ReadonlyArray<{
                readonly __typename: "LocationDetail";
                readonly text: string;
                readonly source: {
                    readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
                };
            } | {
                readonly __typename: "CitationDetail";
                readonly text?: string;
                readonly source?: {
                    readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
                };
            } | {
                readonly __typename: "EtymologyDetail";
                readonly text?: string;
                readonly source?: {
                    readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
                };
            } | {
                readonly __typename: "CollectionDetail";
                readonly text?: string;
                readonly source?: {
                    readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
                };
            } | {
                /*This will never be '%other', but we need some
                value in case none of the concrete values match.*/
                readonly __typename: "%other";
            }>;
            readonly verbatimCitation?: string | null;
            readonly typeSpecimen?: string | null;
            readonly speciesTypeKind?: SpeciesGroupType | null;
            readonly typeLocality?: {
                readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
            } | null;
            readonly nameType?: {
                readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
            } | null;
            readonly group?: Group;
            readonly nomenclatureStatus?: NomenclatureStatus;
            readonly taxon: {
                readonly validName: string;
                readonly class_: {
                    readonly oid: number;
                    readonly validName: string;
                    readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
                } | null;
                readonly order: {
                    readonly oid: number;
                    readonly validName: string;
                    readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
                } | null;
                readonly family: {
                    readonly oid: number;
                    readonly validName: string;
                    readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
                } | null;
            };
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        } | null;
    } | null>;
    readonly " $refType": "NameList_connection";
};
export type NameList_connection$data = NameList_connection;
export type NameList_connection$key = {
    readonly " $data"?: NameList_connection$data;
    readonly " $fragmentRefs": FragmentRefs<"NameList_connection">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "oid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validName",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ModelLink_model"
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Article",
    "kind": "LinkedField",
    "name": "source",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeSpecimen",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "speciesTypeKind",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCitationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showCollectionDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showEtymologyDetail"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "showLocationDetail"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showNameDetail"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameList_connection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NameEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Name",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rootName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Taxon",
              "kind": "LinkedField",
              "name": "taxon",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Taxon",
                  "kind": "LinkedField",
                  "name": "class_",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Taxon",
                  "kind": "LinkedField",
                  "name": "order",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Taxon",
                  "kind": "LinkedField",
                  "name": "family",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "condition": "showLocationDetail",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeTags",
                  "plural": true,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": (v6/*: any*/),
                      "type": "LocationDetail",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ]
            },
            {
              "condition": "showCitationDetail",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeTags",
                  "plural": true,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": (v6/*: any*/),
                      "type": "CitationDetail",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "verbatimCitation",
                  "storageKey": null
                }
              ]
            },
            {
              "condition": "showEtymologyDetail",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeTags",
                  "plural": true,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": (v6/*: any*/),
                      "type": "EtymologyDetail",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ]
            },
            {
              "condition": "showCollectionDetail",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeTags",
                  "plural": true,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": (v6/*: any*/),
                      "type": "CollectionDetail",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ]
            },
            {
              "condition": "showNameDetail",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Location",
                  "kind": "LinkedField",
                  "name": "typeLocality",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": "nameType",
                  "args": null,
                  "concreteType": "Name",
                  "kind": "LinkedField",
                  "name": "type",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "group",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nomenclatureStatus",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NameConnection",
  "abstractKey": null
};
})();
(node as any).hash = '0ab7b1268b8efec9e05dcbdce451b8fa';
export default node;
