/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModelChildList_model = {
    readonly __typename: string;
    readonly id?: string;
    readonly numTypeSpecimens?: number;
    readonly numChildren?: number;
    readonly numNames?: number;
    readonly numTypeLocalities?: number;
    readonly numLocations?: number;
    readonly " $refType": "ModelChildList_model";
};
export type ModelChildList_model$data = ModelChildList_model;
export type ModelChildList_model$key = {
    readonly " $data"?: ModelChildList_model$data;
    readonly " $fragmentRefs": FragmentRefs<"ModelChildList_model">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numChildren",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "numLocations",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModelChildList_model",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numTypeSpecimens",
          "storageKey": null
        }
      ],
      "type": "Collection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numNames",
          "storageKey": null
        }
      ],
      "type": "Taxon",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numTypeLocalities",
          "storageKey": null
        }
      ],
      "type": "Location",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "Region",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "Period",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "StratigraphicUnit",
      "abstractKey": null
    }
  ],
  "type": "Model",
  "abstractKey": "__isModel"
};
})();
(node as any).hash = '55a3987d2eef508ac45959336f0c360b';
export default node;
