/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmendationJustification = "conserved_by_the_commission" | "inadvertent_error" | "incorrect_family_group_name" | "prevailing_usage" | "removal_of_mark" | "%future added value";
export type NomenclatureStatus = "anonymous_authorship" | "art_13_nomen_oblitum" | "as_emended" | "available" | "based_on_a_suppressed_name" | "based_on_homonym" | "before_1758" | "collective_group" | "conditional" | "fully_suppressed" | "hybrid_as_such" | "hybrid_name" | "hypothetical_concept" | "inconsistently_binominal" | "incorrect_original_spelling" | "incorrect_subsequent_spelling" | "informal" | "infrasubspecific" | "ites_name" | "justified_emendation" | "mandatory_change" | "multiple_words" | "name_combination" | "no_type_specified" | "nomen_novum" | "nomen_nudum" | "not_based_on_a_generic_name" | "not_explicitly_new" | "not_intended_as_a_scientific_name" | "not_latin_alphabet" | "not_nominative_singular" | "not_published_with_a_generic_name" | "not_used_as_genus_plural" | "not_used_as_valid" | "partially_suppressed" | "preoccupied" | "rejected_by_fiat" | "reranking" | "subsequent_usage" | "teratological" | "type_not_treated_as_valid" | "unjustified_emendation" | "unlisted" | "unpublished" | "unpublished_electronic" | "unpublished_pending" | "unpublished_supplement" | "unpublished_thesis" | "variant" | "variety_or_form" | "work_of_extant" | "zoological_formula" | "%future added value";
export type NameTags_name = {
    readonly tags: ReadonlyArray<{
        readonly __typename: "Conserved";
        readonly opinion: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "FullySuppressedBy";
        readonly opinion: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "IncorrectOriginalSpellingOf";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "IncorrectSubsequentSpellingOf";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "JustifiedEmendationOf";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly justification: EmendationJustification;
        readonly comment: string | null;
    } | {
        readonly __typename: "MandatoryChangeOf";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "NomenNovumFor";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "NomenOblitum";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "PreoccupiedBy";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "PrimaryHomonymOf";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "SecondaryHomonymOf";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "NotPreoccupiedBy";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "PartiallySuppressedBy";
        readonly opinion: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "Rejected";
        readonly opinion: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "ReversalOfPriority";
        readonly over: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly opinion: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "SelectionOfPriority";
        readonly over: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly optionalSource: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        } | null;
        readonly comment: string | null;
    } | {
        readonly __typename: "SelectionOfSpelling";
        readonly optionalSource: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        } | null;
        readonly comment: string | null;
    } | {
        readonly __typename: "SubsequentUsageOf";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "TakesPriorityOf";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "UnjustifiedEmendationOf";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "VariantOf";
        readonly name: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "Condition";
        readonly status: NomenclatureStatus;
        readonly comment: string | null;
    } | {
        readonly __typename: "ValidUse";
        readonly source: {
            readonly " $fragmentRefs": FragmentRefs<"ModelLink_model">;
        };
        readonly comment: string | null;
    } | {
        readonly __typename: "VarietyOrForm";
        readonly comment: string | null;
    } | {
        readonly __typename: "NotUsedAsValid";
        readonly comment: string | null;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly " $refType": "NameTags_name";
};
export type NameTags_name$data = NameTags_name;
export type NameTags_name$key = {
    readonly " $data"?: NameTags_name$data;
    readonly " $fragmentRefs": FragmentRefs<"NameTags_name">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ModelLink_model"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Article",
  "kind": "LinkedField",
  "name": "opinion",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Name",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Name",
  "kind": "LinkedField",
  "name": "over",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Article",
  "kind": "LinkedField",
  "name": "optionalSource",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v8 = [
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NameTags_name",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "Conserved",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "FullySuppressedBy",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "IncorrectOriginalSpellingOf",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "IncorrectSubsequentSpellingOf",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "justification",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "type": "JustifiedEmendationOf",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "MandatoryChangeOf",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "NomenNovumFor",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "NomenOblitum",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "PreoccupiedBy",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "PrimaryHomonymOf",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "SecondaryHomonymOf",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "NotPreoccupiedBy",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "PartiallySuppressedBy",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "Rejected",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v6/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "type": "ReversalOfPriority",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v6/*: any*/),
            (v7/*: any*/),
            (v2/*: any*/)
          ],
          "type": "SelectionOfPriority",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v7/*: any*/),
            (v2/*: any*/)
          ],
          "type": "SelectionOfSpelling",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "SubsequentUsageOf",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "TakesPriorityOf",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "UnjustifiedEmendationOf",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "VariantOf",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "type": "Condition",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Article",
              "kind": "LinkedField",
              "name": "source",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "type": "ValidUse",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v8/*: any*/),
          "type": "VarietyOrForm",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v8/*: any*/),
          "type": "NotUsedAsValid",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Name",
  "abstractKey": null
};
})();
(node as any).hash = 'f5a4b6970faea209f6354c977fb767ca';
export default node;
